@import "../common.scss";

.preview {
  border-radius: $preview-border-radius;
  border: 0.8px solid $light-grey;
  box-sizing: border-box;

  &.raw,
  &.video,
  &.instagram {
    display: block;
    object-fit: cover;
  }

  &.raw {
    image-rendering: pixelated;
  }

  &.instagram {
    padding: 5px;
  }
}
