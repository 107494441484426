@import "../common.scss";
@import "/src/styles/transitions";

.container {
  color: $font-grey;
  font-weight: 600;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $border-grey;
  }

  .parent {
    display: flex;
    align-items: flex-start;
    font-size: 11px;
    line-height: 15px;

    .preview {
      flex: 0 0 auto;
      margin-right: 12px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        border-radius: $preview-border-radius;
        inset: 0;
        background-color: rgba($black, 0.4);
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../../../icons/icon-link.svg");
        opacity: 0;
        transition: opacity 0.1s ease-out;
      }

      &:active::after {
        opacity: 1;
      }
    }

    .details {
      flex: 1 1 0;
      min-width: 0;
      display: flex;
      flex-direction: column;

      .title {
        display: flex;
        align-items: flex-start;
        margin: 2px 0 4px;
        column-gap: 4px;

        > svg,
        .cancel {
          flex: 0 0 auto;
        }

        .cancel {
          @include neutral-button;
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../../../../icons/icon-cancel-2.svg");
          width: 16px;
          height: 16px;
        }
      }

      .stats {
        display: flex;
        align-items: flex-start;
        margin-top: 8px;
        column-gap: 6px;

        .speed,
        .expand {
          flex: 0 0 auto;
        }

        .speed.error {
          color: $red-error;
        }

        .expand {
          @include neutral-button;
          position: relative;
          height: 15px;
          width: 16px;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 5px;
            border-style: solid;
            border-color: $font-grey;
            border-width: 0 3px 3px 0;
            padding: 3px;
            transform: rotate(45deg);
          }

          &.expanded::after {
            top: 4px;
            transform: rotate(-135deg);
          }
        }
      }

      .title .description,
      .stats .status {
        flex: 1 1 0;
        min-width: 0;
        @include ellipsis;
      }
    }
  }
}

@include expand(var(--zf-activity-monitor-parent-job-transition-duration));
