@import "/src/styles/shared";

.collapsed,
.overlay {
  z-index: $overlay-z-index - 1;
}

.overlay {
  background: transparent;
  pointer-events: none;
}

.expanded {
  max-height: 46%;
  border-radius: 5px 5px 0 0;
  background: $primary-orange;
  pointer-events: auto;

  .header {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: normal;
    text-transform: none;
    padding: 7px 0 5px;
    border-bottom: none;
    color: $white;
    position: relative;

    .button {
      @include neutral-button;
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      top: 1px;
      position: absolute;

      &.collapse {
        background-image: url("../../../icons/icon-collapse.svg");
        right: 26px;
      }

      &.close {
        background-image: url("../../../icons/icon-close-3.svg");
        right: 3px;

        &:disabled {
          opacity: 0.3;
        }
      }
    }
  }

  .content {
    background: $white;
    padding: 0 16px 0 8px;
  }
}

.collapsed {
  position: fixed;
  right: 21px;
  bottom: 92px;
  background: $white;
  border-radius: 50%;
  box-sizing: border-box;
  border-style: solid;
  border-color: $warm-grey-dark;
  @include flex-center;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba($black, 0.25);
  cursor: pointer;
  text-align: center;
  font-weight: 600;

  .ring {
    position: absolute;
    transform: rotate(-90deg);

    circle {
      fill: transparent;
      stroke: $primary-orange;
      transition: stroke-dashoffset 1000ms;
    }
  }

  .status {
    font-size: 15px;
    line-height: 20px;
    color: $font-dark;
  }

  .counts {
    font-size: 12px;
    line-height: 16px;
    color: $font-light;

    &.error {
      color: $red-error;
    }
  }

  .close {
    @include neutral-button;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../icons/icon-close-4.svg");
    width: 54px;
    height: 54px;
    top: -10px;
    right: -12px;
    position: absolute;
  }
}

.tooltip {
  @include tooltip(8px 13px);
  margin-left: 1px;
  z-index: 1;
}
