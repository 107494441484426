// Colors
$white: #ffffff;
$black: #000000;
$warm-grey: #f6f5f3;
$warm-grey-dark: #f0eeea;
$warm-grey-dark-2: #dddddd;
$warm-grey-dark-3: #d8d8d8;
$dark-grey: #1e1f22;
$light-grey: #cccccc;
$footer-gray: #f4f4f4;
$charcoal-grey: #333333;
$font-light: #a3a3a3;
$font-dark: #606060;
$font-grey: #757575;
$input-error: #ca353a;
$red-error: #c11616;
$red-status-order: #ea2e2e;
$placeholder: #c2c0bd;
$dividers: #ececec;
$primary-orange: #ff5a00;
$secondary-orange: #ff7700;
$third-orange: #fb9c1b;
$dark-orange: #d54b00;
$primary-blue: #1a73e8;
$secondary-blue: #006abe;
$third-blue: #2e93ea;
$primary-yellow: #fceb39;
$light-blue: #e8f3fd;
$light-yellow: #fdfce8;
$light-green: #e9f5d6;
$light-orange: #fcefdf;
$light-red: #fde8e8;
$siteBackground: #f8f7f7;
$green: #0d9f37;
$green-2: #068389;
$green-2-dark: #04686d;
$primary-green: #8fcc33;
$secondary-green: #248c1d;
$steel-grey: #52545a;
$overlay: rgba($black, 0.3);
$zen-teal: #068389;
$teal-gradient: #008288;
$link-blue: #057aff;
$red-opacity: #eb0000;
$red-shadow: rgba(235, 0, 0, 0.3);
$secondary-tangerine: #ffac00;
$zen-teal-light: rgba($zen-teal, 0.05);
$link-blue-light: rgba($link-blue, 0.05);
$secondary-tangerine-light: rgba($secondary-tangerine, 0.05);
$red-error-light: rgba($red-error, 0.05);
$zen-tan: #9c8a71;
$border-grey: #efefef;
$alert-yellow-background: #fdfbe7;
$alert-red-background: #fee7e8;
$alert-red: #c11615;
$alert-yellow: $secondary-tangerine;
$background-grey: #f7f6f4;

// Heights
$orders-header-height: 99px;
$notifications-tab-height: 68px;
$input-height: 50px;
$page-header-height: 46px;
$notifications-content-height: calc(100vh - 270px);

// Other
$overlay-z-index: 10000;
$trial-banner-z-index: 9999;
$site-show-duration: 1s;
