@import "/src/styles/shared";

.expandedActivityMonitorContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px 5px 0 0;
  bottom: 0px;
  z-index: 1;
  display: flex;
  align-items: flex-end;

  .hiddenCloseScreen {
    position: absolute;
    padding: 0px;
    background: rgba($black, 0.65);
    height: 100%;
    width: 100%;
    top: 0px;
    z-index: -1;
    border: none;
  }
}

.minimizedActivityMonitorContainer {
  position: absolute;
  bottom: 92px;
  right: 21px;
}
