@import "/src/styles/shared";

.modal {
  box-sizing: border-box;
  height: 100%;

  top: 0;
  max-width: 864px;
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px $light-grey;
  background-color: #f9f9f9;
  margin: 0;
  align-self: stretch;
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $light-grey;
    padding: 0;
    margin: 0;
  }
  .header {
    line-height: 44px;
    text-align: center;
    background: white;
    font-weight: bold;
  }
  
  .content {
    height: calc(100% - 44px);
  }
}


.hidden {
  display: none;
}

.modalOverlay {
  // higher than any iframes with ridiculously high z-index
  z-index: 2000000;
}
