@import "/src/styles/shared";

.approveContent,
.cancelContent {
  color: $dark-grey;

  .title {
    font-weight: 800;
    line-height: 1.25;
  }

  .subTitle {
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 24px;
  }

  .noteTitle {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 5px;
  }

  .note {
    width: 100%;
    height: 80px;
    padding: 8px;
    font-size: 14px;

    &::placeholder {
      color: $light-grey;
    }
  }
}

.cancelContent {
  .subTitle {
    margin-bottom: 44px;
  }
}
