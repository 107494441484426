@import "/src/styles/shared";
@import "/src/styles/transitions";

.container {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  max-width: calc(100% - 32px);
  padding: 8px 24px;
  border-radius: 8px;
  z-index: $overlay-z-index + 1;

  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  overflow-wrap: break-word;
  white-space: pre-line;

  &.success {
    background-color: #0db43d;
    color: $white;
  }
  &.error {
    background-color: $red-error;
    color: $white;
  }
}

@include fade(var(--zf-toast-transition-duration));
