@import "/src/styles/shared";

.modal {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  background: $warm-grey;
  animation-name: full-screen-animation;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.26px;
    text-transform: uppercase;
    text-align: center;
    padding: 16px 0;
    border-bottom: 1px solid $dividers;
    color: $dark-grey;
  }

  .content {
    flex: 1 1 0;
    min-height: 0;
    @include vertical-scroll;
  }

  .footer {
    flex: 0 0 auto;
    border-top: 1px solid $dividers;
    background: $white;
    padding: 12px 16px 19px;
  }

  @keyframes full-screen-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
