@mixin fade($duration) {
  .fade {
    will-change: opacity;

    &.fadeEnter,
    &.fadeAppear {
      opacity: 0;
    }

    &.fadeEnterActive,
    &.fadeAppearActive {
      transition: opacity $duration ease-out 0ms;
      opacity: 1;
    }

    &.fadeEnterDone,
    &.fadeAppearDone {
      opacity: 1;
    }

    &.fadeExit {
      opacity: 1;
    }

    &.fadeExitActive {
      transition: opacity $duration ease-out 0ms;
      opacity: 0;
    }

    &.fadeExitDone {
      opacity: 0;
    }
  }
}

@mixin slide-left($duration) {
  .slideLeft {
    will-change: transform;

    &.slideLeftEnter,
    &.slideLeftAppear {
      transform: translateX(100%);
    }
    &.slideLeftEnterActive,
    &.slideLeftAppearActive {
      transition: transform $duration ease-out 0ms;
      transform: translateX(0%);
    }
    &.slideLeftEnterDone,
    &.slideLeftAppearDone {
      transform: translateX(0%);
    }
    &.slideLeftExit {
      transform: translateX(0%);
    }
    &.slideLeftExitActive {
      transition: transform $duration ease-out 0ms;
      transform: translateX(-100%);
    }
    &.slideLeftExitDone {
      transform: translateX(-100%);
    }
  }
}

@mixin slide-right($duration) {
  .slideRight {
    will-change: transform;

    &.slideRightEnter,
    &.slideRightAppear {
      transform: translateX(-100%);
    }
    &.slideRightEnterActive,
    &.slideRightAppearActive {
      transition: transform $duration ease-out 0ms;
      transform: translateX(0%);
    }
    &.slideRightEnterDone,
    &.slideRightAppearDone {
      transform: translateX(0%);
    }
    &.slideRightExit {
      transform: translateX(0%);
    }
    &.slideRightExitActive {
      transition: transform $duration ease-out 0ms;
      transform: translateX(100%);
    }
    &.slideRightExitDone {
      transform: translateX(100%);
    }
  }
}

@mixin expand($duration, $heightVarName: --full-height) {
  .expand {
    will-change: height;

    &.expandEnter,
    &.expandAppear {
      overflow: hidden;
      height: 0;
      pointer-events: none;
    }
    &.expandEnterActive,
    &.expandAppearActive {
      transition: height $duration ease-out 0ms;
      height: var($heightVarName);
    }
    &.expandEnterDone,
    &.expandAppearDone {
      overflow: auto;
    }
    &.expandExit {
      overflow: hidden;
      pointer-events: none;
      height: var($heightVarName);
    }
    &.expandExitActive {
      transition: height $duration ease-out 0ms;
      height: 0;
    }
    &.expandExitDone {
      display: none;
    }
  }
}

@mixin pop($duration) {
  .pop {
    will-change: opacity, transform;

    &.popEnter,
    &.popAppear {
      opacity: 0;
      transform: scale(0);
    }

    &.popEnterActive,
    &.popAppearActive {
      transition: opacity $duration ease-out 0ms, transform $duration ease-out 0ms;
      opacity: 1;
      transform: scale(1);
    }

    &.popEnterDone,
    &.popAppearDone {
      opacity: 1;
      transform: scale(1);
    }

    &.popExit {
      opacity: 1;
      transform: scale(1);
    }

    &.popExitActive {
      transition: opacity $duration ease-out 0ms, transform $duration ease-out 0ms;
      opacity: 0;
      transform: scale(0);
    }

    &.popExitDone {
      opacity: 0;
      transform: scale(0);
    }
  }
}
