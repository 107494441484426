@import "/src/styles/shared";

.container {
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-start;
  padding: 7px 0 0 8px;

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 6px;
  }

  .preview {
    flex: 0 0 auto;
    margin-right: 20px;
  }

  .details {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: flex-start;
      margin: 8px 0 5px;
      column-gap: 8px;

      .description {
        flex: 1 1 0;
        min-width: 0;
        @include ellipsis;
      }

      .status,
      > img {
        flex: 0 0 auto;
      }

      .status.error {
        color: $red-error;
      }

      > img {
        height: 12px;
        width: 12px;
        padding: 1px 0 1px 2px;
        cursor: pointer;
        box-sizing: content-box;
      }
    }
  }
}
