@import "/src/styles/shared";
@import "../base.scss";

.overlay {
  @include flex-center;
  padding: 0 24px;

  .modal {
    @include modal-dialog-animation;
    width: 100%;
    border-radius: 16px;
    background: $white;
    box-shadow: 0 20px 80px 0 rgba($black, 0.14), 0 0 0.5px 0 rgba($black, 0.2);

    .header {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.26px;
      text-transform: uppercase;
      text-align: center;
      padding: 12px 20px;
      border-bottom: 1px solid $dividers;
      color: $dark-grey;
    }

    .content {
      padding: 24px 32px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $font-dark;
      overflow-wrap: break-word;
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 24px;

      .button {
        max-width: 200px;

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }
}
