@import "/src/styles/shared";

.container {
  position: relative;
  height: 3px;
  width: 100%;
  background: $dividers;

  &,
  .progress {
    border-radius: 2px;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: $primary-orange;

    &.error {
      background: $red-error;
    }
  }
}
