@import "/src/styles/shared";

.accountSettings,
.logout {
  &.modal {
    background: $white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  .settingsContent {
    padding: 8px 24px 0;
  }

  .settingsOption {
    display: flex;
    align-items: center;
    padding: 5px 16px;
    position: relative;
    font-weight: 600;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .arrowIcon,
  .toggle {
    position: absolute;
    right: 0;
  }

  .logout {
    width: auto;
    padding: 0 53px;
  }

  .footer {
    display: flex;
    justify-content: center;
  }
}

.settingsOverlay {
  &.transparent {
    background: transparent;
  }
}

.logout {
  text-align: center;
  padding-bottom: 14px;

  .subTitle {
    font-size: 12px;
    font-weight: 600;
    color: $font-light;
    margin-bottom: 16px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: $font-dark;
    margin-bottom: 24px;
  }

  .button {
    &:first-of-type {
      margin-bottom: 8px;
    }

    width: 200px;
  }
}
