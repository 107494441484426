@import "./shared.scss";
@import "@zenfolio/core-components/dist/index.css";
@import "@zenfolio/nz-core-components/dist/main.css";
@import "font-awesome/css/font-awesome.css";
@import "cropperjs/dist/cropper.css";

@import url("https://fonts.creatorcdn.com/apis/css?family=Nunito+Sans:300,400,500,600,700,800,900");
@font-face {
  font-family: "Brygada 1918";
  src: url("https://app.zenfolio.com/storage/fonts/Brygada1918-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

html {
  overscroll-behavior: none;

  &.hidePendo #pendo-base {
    display: none !important;
  }
}

body {
  font-family: $nunito-sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  background: transparent;
  -webkit-tap-highlight-color: transparent;

  &.hideScroll,
  &.disableScroll {
    overflow: hidden;
  }
}

#root {
  height: 100%;
}

img {
  color: transparent;
}

p {
  margin: 0;
}
