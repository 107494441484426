@import "/src/styles/shared";

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: $overlay-z-index + 1;
  top: 0;
  background: $white;
  display: flex;
  flex-direction: column;
  line-height: normal;
  text-align: center;

  .close {
    align-self: flex-end;
    padding: 10px;
    cursor: pointer;
  }

  .body {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 44px;

    .brand {
      font-size: 18px;
      font-weight: normal;
      color: $font-grey;
      margin-top: 22px;
    }

    .message {
      font-size: 24px;
      font-weight: bold;
      color: $black;
    }
  }
}
