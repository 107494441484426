.root {
  line-height: 20px;
  font-size: 15px;
  font-weight: 600;

  margin-right: 6px;

  .underline {
    text-decoration: underline;
    text-underline-offset: 1px;
  }
}
