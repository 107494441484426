@import "./fonts.scss";

@mixin spinner() {
  animation: circ-anim 1s linear infinite;

  @keyframes circ-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin vertical-scroll() {
  overflow-y: auto;
  overflow-x: hidden;
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin neutral-button {
  padding: 0;
  font-size: 0;
  border-radius: 0;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    opacity: 1;
    cursor: not-allowed;
  }
}

@mixin page-header {
  background-color: $dark-grey;
  color: $white;
  height: $page-header-height;
}

@mixin hide-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

@mixin modal-button($width) {
  width: $width;
  margin: 0 auto;
  display: flex;
}

@mixin center-absolute() {
  position: absolute;
  inset: 0;
  margin: auto;
}

@mixin tooltip($padding, $with-links: false) {
  --rt-opacity: 1;
  --rt-color-dark: #{rgba(#383c42, 0.95)};

  box-sizing: border-box;
  padding: $padding;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  text-align: left;

  @if $with-links {
    a {
      color: $primary-orange;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@mixin showOverTrialBanner {
  z-index: $overlay-z-index;
  position: relative;
}
