@use "sass:math";
@import "/src/styles/shared";

$mockup-height: 812px;

.container {
  position: fixed;
  inset: 0;
  z-index: 100000;
  background: $zen-teal;

  &.secondary {
    background: $warm-grey;

    &.publicApiMode {
      background: $white;
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  &.closing {
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .header {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px 0;

      img {
        width: 200px;
        height: 71px;
        margin-bottom: 8px;
      }

      em {
        font-style: normal;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 2px;
        color: rgba($white, 0.6);
        text-align: center;
      }
    }

    .spinner {
      flex: 0 0 auto;
    }
  }

  .copyright {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    color: rgba($white, 0.5);
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: math.percentage(math.div(40px, $mockup-height));
  }
}
