@import "/src/styles/shared.scss";

@mixin iosCrystallineBackgroundEffect {
  background-color: rgba(255, 255, 255, 0.49);
  box-shadow: 0 10px 15px rgb(0 0 0 / 20%);
  backdrop-filter: blur(33px);
  background-blend-mode: overlay;
}

.root {
  width: calc(100% - 20px);

  @media (orientation: landscape) {
    width: fit-content;
  }

  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: $trial-banner-z-index;

  border-radius: 16px;

  @include iosCrystallineBackgroundEffect;

  &.innerContainer {
    display: flex;
    justify-content: space-between;

    .content {
      display: flex;
      padding: 10px;

      .alertIcon {
        margin-right: 6px;
        height: 38px;
        width: 38px;
      }

      .text {
        color: $black;
        line-height: 1.33;

        .primary {
          height: 16px;
          font-size: 12px;
          font-weight: 800;
        }

        .viewPlans {
          line-height: 20px;

          font-size: 13px;
          font-weight: 600;

          color: $green;

          text-decoration: underline;
        }
      }
    }

    .closeContainer {
      position: relative;
      width: auto;
      min-width: 32px;

      .closeIcon {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }
}
