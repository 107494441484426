@import "/src/styles/shared";

.modal {
  width: 100%;
  max-height: 100%;
  border-radius: 8px 8px 0 0;
  background: $warm-grey;
  box-shadow: 0 20px 80px 0 rgba($black, 0.14), 0 0 0.5px 0 rgba($black, 0.2);
  animation-name: bottom-panel-animation;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.26px;
    text-transform: uppercase;
    text-align: center;
    padding: 16px 0 12px;
    border-bottom: 1px solid $dividers;
    color: $dark-grey;
  }

  .content {
    flex: 1 1 0;
    min-height: 0;
    padding: 16px;
    overflow-wrap: break-word;
    @include vertical-scroll;
  }

  .footer {
    flex: 0 0 auto;
    border-top: 1px solid $dividers;
    margin: 0 16px;
    padding: 16px 0 30px;
  }

  @keyframes bottom-panel-animation {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }
}
