@use "sass:math";
@import "/src/styles/shared";

$large-height: 50px;
$normal-height: 44px;
$small-height: 32px;

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  font-weight: 800;
  padding: 0;
  width: 100%;
  height: $normal-height;
  border-radius: math.div($normal-height, 2);
  font-size: 16px;
  color: $white;
  background: $primary-orange;
  transition: all 0.2s linear;

  &.small {
    height: $small-height;
    border-radius: math.div($small-height, 2);
    font-size: 12px;
  }

  &.large {
    height: $large-height;
    border-radius: math.div($large-height, 2);
    font-size: 18px;
    font-weight: bold;
  }

  &.secondary {
    background: $dark-grey;

    &.dark {
      color: $dark-grey;
      background: $white;
    }
  }

  &.plain {
    color: $dark-grey;
    background: $white;
    border: 2px solid $dark-grey;

    &.dark {
      color: $white;
      background: $dark-grey;
      border-color: $white;
    }
  }

  &.caution {
    background: $red-error;
  }

  &.blue {
    background: $primary-blue;
  }

  &.error {
    color: $red-error;
    background: transparent;
    border: 1px solid $red-error;
  }

  &:active,
  &.canHover:hover {
    background: $dark-orange;

    &.secondary {
      background: $black;

      &.dark {
        background: $dividers;
      }
    }

    &.plain {
      background: $white;

      &.dark {
        background: $charcoal-grey;
        border-color: $dividers;
      }
    }

    &.caution {
      background: $red-error;
    }

    &.blue {
      background: $secondary-blue;
    }

    &.error {
      background: transparent;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 1;
  }

  &:not(.pending):disabled {
    border: none !important;
    background: $dividers !important;
    color: $font-light !important;
  }
}
