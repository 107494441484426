@import "/src/styles/shared";

@mixin modal-dialog-animation {
  animation-name: dialog-animation;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  @keyframes dialog-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
