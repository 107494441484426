@import "/src/styles/shared.scss";

.root {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  background-color: $white;
  overflow: hidden;

  .iFrame {
    visibility: hidden;
  }

  .spinner {
    @include center-absolute;
  }
}
