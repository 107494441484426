.container {
  height: 100%;

  > iframe {
    border: none;
  }
}

.hidden {
  visibility: hidden;
}
