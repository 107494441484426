@import "/src/styles/shared";

.overlay {
  position: fixed;
  inset: 0;
  background: rgba($black, 0.65);
  z-index: $overlay-z-index;
  box-sizing: content-box;

  .closing {
    animation-direction: reverse;
  }

  .noAnimation {
    animation-name: none !important;
  }

  &.hidden {
    display: none;
  }
}
