@import "/src/styles/shared";

.commentModal {
  .header {
    text-transform: none;
    color: $dark-grey;
  }
}

.spinnerWrapper {
  @include flex-center
}

.container {
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  .imageWrapper {
    padding: 0 0 14px;
    @include flex-center();
    flex-direction: column;
    background: $warm-grey;
    min-height: 200px;

    .image {
      padding: 16px;
      max-height: 25vh;
      max-width: 70vw;
      height: 500px;
      width: 448px;
      position: relative;
      .videoThumbNail {
        min-width: 150px;
        min-height: 150px;
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
      }
      img {
        &.commentCoverImg {
          width: 100% !important;
          height: 100% !important;
          object-fit: contain !important;
        }
      }
    }

    .photoName {
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      color: $font-grey;
    }
  }

  .comments {
    padding: 16px 24px;

    .comment {
      margin-bottom: 20px;
    }

    .name {
      font-size: 12px;
      font-weight: bold;
    }

    .text {
      margin: 2px 0;
    }

    .time {
      font-size: 12px;
      color: $font-grey;
    }
  }
}

.replyPanel {
  position: sticky;
  bottom: 0;
  padding: 16px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  .replyBoxWrapper {
    position: relative;
    padding: 11px 48px 11px 16px;
    border: 1px solid $warm-grey-dark-3;
    border-radius: 22px;
    background-color: $warm-grey-dark-3;
    .inputWrapper {
      textarea {
        font-size: 16px;
        font-weight: 600;
        height: 18px;
        color: $dark-grey;
        padding: unset;
        background-color: $warm-grey-dark-3;
       
        border: none;
        &::placeholder {
          font-size: 16px;
          font-weight: 600;
          color: $font-grey;
        }
      }
    }
    .textBoxIcon {
      position: absolute;
      top: 9px;
      right: 16px;
    }
  }

 
}
