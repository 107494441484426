@import "/src/styles/shared";

.close {
  width: 28px;
  height: 28px;
  padding: 5px;
  top: 7px;
  right: 7px;
  cursor: pointer;
  position: absolute;
}
